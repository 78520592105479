<template>
  <vx-card title="Order List" class="mt-base">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="orderData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Order ID</vs-th>
          <vs-th sort-key="clinic">Clinic</vs-th>
          <vs-th>Date Placed</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th>Order Total</vs-th>
          <vs-th>Total Change</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].orderNumber">
              {{
                data[indextr].orderNumber
              }}
            </vs-td>
            <vs-td :data="data[indextr].shippingDetails.companyName">
              {{
                data[indextr].shippingDetails.companyName
              }}
            </vs-td>
            <vs-td>
              {{data[indextr].createdAt | date_formatter}}
            </vs-td>
            <vs-td :data="data[indextr].status">
              {{
                data[indextr].status
              }}
            </vs-td>
            <vs-td :data="data[indextr].subTotal">
              ${{
                (data[indextr].subTotal) | formatDecimals
              }}
            </vs-td>
            <vs-td :data="data[indextr].total">
              ${{
                (data[indextr].total) | formatDecimals
              }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View Order Details" position="left">
                <vs-button
                  type="border"
                  size="small"
                  @click="viewOrderHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-eye"
                  class="mt-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
           </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2">
        {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
        }}
        of {{ totalDocs }}
      </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import moment from "moment";

export default {
  name: 'OrderListBySupplier',
  components: {
    vSelect,
  },
  props:{
    supplierId: {
      type: String
    }
  },
  data() {
    return {
      id: this.supplierId,
      isMounted: false,
      currentPage: 1,
      totalDocs: 0,
      page: 1,
      noDataText: 'Loading...',
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      serverResponded: false,
      awaitingSearch: null,
      orderData: ''
    };
  },
  methods: {
    ...mapActions("supplier", [
      "getSupplierOrderList"
    ]),
    moment(date) {
      return moment(date);
    },
    async fetchOrderListBySupplier(id){
      let self=this;
      let info = {
        dataTableParams: this.dataTableParams,
        id: id
      };
      await this.getSupplierOrderList(info).then(res => {
        self.orderData = res.data.data.docs;
        self.supplierId = self.orderData._id;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = 'No Order Available'
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchOrderListBySupplier(this.id);
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchOrderListBySupplier(this.id);
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchOrderListBySupplier(this.id);
    },
    viewOrderHandler(id){
      const data = {
        orderId: id,
        supplierId: this.id
      };
      this.$emit('viewOrderDetails', data );
    },
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.fetchOrderListBySupplier(this.id);
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchOrderListBySupplier(this.id);
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  async created() {
    this.id = this.supplierId;
    await this.fetchOrderListBySupplier(this.id);
  }
};
</script>

<style scoped>
</style>
